import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TextImage from "../components/text-image"
import CTA from '../components/cta'

const Kontakt = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <TextImage
        asHeader
        imgUrl="/contact.jpg"
        heading="Fachowiec od pieców Brodnica"
        text='<strong>FHU Prolan Jan Nowakowski</strong><br>Telefon: <a href="tel:+48501630051">+48 501 630 051</a>'
      />
      <CTA />
    </Layout>
  )
}

export const Head = () => <Seo title="Kontakt" />

export default Kontakt

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
